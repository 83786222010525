const RECITALADD = "RECITALADD";
const RECITALUPDATE = "RECITALUPDATE";
const RECITALDELETE = "RECITALDELETE";
const LESSONADD = "LESSONADD";
const LESSONUPDATE = "LESSONUPDATE"; 
const LESSONDELETE = "LESSONDELETE";
const OBJECTIVEADD = "OBJECTIVEADD";
const OBJECTIVEUPDATE = "OBJECTIVEUPDATE"; 
const OBJECTIVEDELETE = "OBJECTIVEDELETE";
const ADDTHEORY = "ADDTHEORY";
const UPDATETHEORY= "UPDATETHEORY";
const ADDLESSONVIDEO = "ADDLESSONVIDEO"; 
const REMOVELESSONVIDEO = "REMOVELESSONVIDEO";
const ADDOBJECTIVEVIDEO = "ADDOBJECTIVEVIDEO";
const REMOVEOBJECTIVEVIDEO = "REMOVEOBJECTIVEVIDEO";
const ADDBPMVIDEO = "ADDBPMVIDEO";
const UPDATEBPMVIDEO = "UPDATEBPMVIDEO";
const DELETEBPMVIDEO = "DELETEBPMVIDEO";
const ADDMUSESCORE = "ADDMUSESCORE";
const UPDATEMUSESCORE = "UPDATEMUSESCORE";
const DELETEMUSESCORE = "DELETEMUSESCORE";
const OBJECTIVEQUESTIONS = 'OBJECTIVEQUESTIONS';
const CLASSSTUDENTLIST = "CLASSSTUDENTLIST";
export { RECITALADD, RECITALUPDATE, RECITALDELETE, LESSONADD, LESSONUPDATE, LESSONDELETE, OBJECTIVEADD,
    OBJECTIVEUPDATE, OBJECTIVEDELETE, ADDTHEORY, UPDATETHEORY, ADDLESSONVIDEO, REMOVELESSONVIDEO,  ADDOBJECTIVEVIDEO, 
    REMOVEOBJECTIVEVIDEO, ADDBPMVIDEO, UPDATEBPMVIDEO, DELETEBPMVIDEO, ADDMUSESCORE, UPDATEMUSESCORE, DELETEMUSESCORE,
    OBJECTIVEQUESTIONS,CLASSSTUDENTLIST};
