import { MYSCHOOLS, MYTODAYSESSION, MYCLASSROOMSINSTRUMENTS, SCHEDULELIST, STANDARDLIST, SECTIONLIST, SESSIONLIST, SESSIONADD, MYTODAYSESSIONPROGRESS, SCHOOLEVENTS } from "../Types/DashboardTypes"


const initialState = {
    mySchoolsData: undefined,
    myTodaysSessionData: undefined,
    myClassroomsInstrumentData: undefined,
    scheduleListData: undefined,
    standardListData: undefined,
    sectionListData: undefined,
    sessionListData: undefined,
    sessionAdd: undefined,
    data: null,
    myTodaysSessionProgressData: undefined,
    schoolEventData: undefined
}

const reducer = (state = initialState, action) => {
    const modifiedState = { ...state }
    switch (action.type) {
        case MYSCHOOLS:

            return {
                ...modifiedState,
                mySchoolsData: action.payload.mySchoolsData
            }
        case MYTODAYSESSION:

            return {
                ...modifiedState,
                myTodaysSessionData: action.payload.myTodaysSessionData
            }

        case MYCLASSROOMSINSTRUMENTS:

            return {
                ...modifiedState,
                myClassroomsInstrumentData: action.payload.myClassroomsInstrumentData
            }


        case SCHEDULELIST:

            return {
                ...modifiedState,
                scheduleListData: action.payload.scheduleListData
            }
        case STANDARDLIST:

            return {
                ...modifiedState,
                standardListData: action.payload.standardListData
            }

        case SECTIONLIST:

            return {
                ...modifiedState,
                sectionListData: action.payload.sectionListData
            }

        case SESSIONLIST:
            return {
                ...modifiedState,
                sessionListData: action.payload.sessionListData
            }

        case SESSIONADD:
            return {
                ...modifiedState,
                sessionListData: action.payload.sessionListData
            }
        case MYTODAYSESSIONPROGRESS:

            return {
                ...modifiedState,
                myTodaysSessionProgressData: action.payload.myTodaysSessionProgressData
            }

        case SCHOOLEVENTS:

            return {
                ...modifiedState,
                schoolEventData: action.payload.schoolEventData
            }




        default: { }
    }

    return modifiedState;
}

export function createSessionAction(payload) {
    return {
        type: SESSIONADD,
        payload: payload
    };
}

export default reducer; 