import { ACTIVITYADD, ACTIVITYUPDATE, ACTIVITYDELETE } from "../Types/ActivityTypes"

const initialState = {
    createNewActivityData: undefined,
    updateActivityData: undefined,
    deleteActivityData: undefined
}
let messageNotificationCallback = null;

function hidePreviousMessageNotification() {
    if (messageNotificationCallback) {
        messageNotificationCallback();
    }
}
const ActivityReducers = (state = initialState, action) => {
    const modifiedState = { ...state }
    switch (action.type) {
        case ACTIVITYADD:
            hidePreviousMessageNotification();
            return {
                ...modifiedState,
                createNewActivityData: action.payload
            }
        case ACTIVITYUPDATE:
            return {
                ...modifiedState,
                updateActivityData: action.payload
            }

        case ACTIVITYDELETE:

            return {
                ...modifiedState,
                deleteActivityData: action.payload
            }
        default: { }
    }

    return modifiedState;
}


export default ActivityReducers;